import React, { useRef, useState, useEffect } from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import { Volume2, VolumeX } from "lucide-react";
import "./styles.css";

function HeroHeader() {
  const { scrollYProgress } = useScroll();
  const opacity = useTransform(scrollYProgress, [0, 0.2], [1, 0]);
  const y = useTransform(scrollYProgress, [0, 0.2], ["0%", "-100%"]);

  return (
    <motion.header className="hero-header" style={{ opacity, y }}>
      <div className="hero-content">
        <h1>CTC Creator Content Machine</h1>
        <h1>Native to feed videos, briefed & optimized for conversion</h1>
      </div>
    </motion.header>
  );
}

function Video({ id }) {
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);
  const extensions = [".mp4", ".MP4"];

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        src={`https://d1x3mzaqjtbeh6.cloudfront.net/videos/video${id}${extensions[0]}`}
        alt={`Video ${id}`}
        autoPlay
        loop
        muted={isMuted}
        playsInline
        className="fit-video"
        onError={(e) => {
          e.target.src = `https://d1x3mzaqjtbeh6.cloudfront.net/videos/video${id}${extensions[1]}`;
        }}
      />
      <h2>{`#00${id}`}</h2>
      <button onClick={toggleMute} className="mute-toggle">
        {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
      </button>
    </div>
  );
}

function VideoPage({ pageNumber, isMobile }) {
  let videos;
  let startIndex;

  if (isMobile) {
    videos = [pageNumber];
  } else {
    startIndex = (pageNumber - 1) * 3 + 1;
    videos = [startIndex, startIndex + 1, startIndex + 2];
  }

  return (
    <section className="video-page">
      {videos.map((videoId) => (
        <Video key={videoId} id={videoId} />
      ))}
    </section>
  );
}

export default function App() {
  const [isMobile, setIsMobile] = useState(false);
  const { scrollYProgress } = useScroll();
  const [randomSequence, setRandomSequence] = useState([]);

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Call once to set initial state
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const totalVideos = 50; // Adjust this number based on your total video count

  useEffect(() => {
    const sequence = Array.from({ length: totalVideos }, (_, i) => i + 1).sort(
      () => Math.random() - 0.5
    );
    setRandomSequence(sequence);
  }, [totalVideos]);

  return (
    <div className="app-container">
      <div>
        <HeroHeader />
      </div>
      <div className="video-pages-container">
        {randomSequence.map((pageNumber) => (
          <VideoPage
            key={pageNumber}
            pageNumber={pageNumber}
            isMobile={isMobile}
          />
        ))}
      </div>
      <motion.div className="progress" style={{ scaleX }} />
    </div>
  );
}
